<template>
  <main class="layout-with-bar bill">
    <div class="layout-with-bar__main bill__main">
      <LoaderDots v-if="$fetchState.pending" />
      <section v-else>
        <div class="bill-header">
          <div class="bill-count">
            <p class="bill-count__title">
              На вашем счете
            </p>
            <p class="bill-count__value">
              {{ getCurrency(referral_bill.value) }}
            </p>
          </div>
          <div class="bill-return">
            <p class="bill-return__title-wrapper">
              <span class="bill-return__title">Вывод средств</span>
              <span class="bill-return__subtitle">
                Вывод денежных средств осуществляется в срок до 10 календарных дней
              </span>
            </p>
            <div class="bill-return__input-wrapper">
              <CustomSelect
                v-model="selectedCard"
                class="bill-return__select-card"
                label-option-name="title"
                :options="cardOptions"
                style-light
              />
              <CustomInput
                v-model="countValue"
                style-light
                class="bill-return__input-count"
                :min="limits.min"
                :max="limits.max"
                float
                type="number"
                autocomplete="off"
              >
                Сумма от {{ limits.min }}
              </CustomInput>
              <Button class="bill-return__button" :disabled="!countValue || isBillLoading" @click="acceptReturnBill">
                <Spinner v-if="isBillLoading" theme="simple" width="36px" height="36px" show />
                <template v-else>
                  Вывод
                </template>
              </Button>
            </div>
            <p class="bill-return__panel-attention">
              Нажимая кнопку «оформить вывод», я согласен с условиями оферты ЮMoney
            </p>
          </div>
        </div>
        <Alert
          v-if="billAlert.type"
          :type="billAlert.type"
          @close="removeBillAlert"
        >
          <template #content>
            <p>{{ billAlert.content }}</p>
          </template>
        </Alert>
        <LazyLoader
          v-if="operations"
          :loading="isPending"
          :next-page="canGetNextPage"
          @update="getNextPageItems"
        ></LazyLoader>
        <h3 class="bill-operations-table__title font font_title-m font_medium">
          История начислений
        </h3>
        <table v-if="operations.length" class="bill-operations-table">
          <thead class="bill-operations-table__header">
            <tr class="bill-operations-table__row bill-operations-table__row--header">
              <th class="bill-operations-table__head" align="left">
                Заказ/событие
              </th>
              <th class="bill-operations-table__head" align="left">
                Дата
              </th>
              <th class="bill-operations-table__head" align="right">
                Операция
              </th>
              <th class="bill-operations-table__head" align="right">
                Начислено/списано
              </th>
            </tr>
          </thead>
          <tbody class="bill-operations-table__body">
            <tr v-for="operation in operations" :key="operation.id" class="bill-operations-table__row">
              <td class="bill-operations-table__column" align="left">
                {{ operation.id }}
              </td>
              <td class="bill-operations-table__column" align="left">
                {{ operation.created_at }}
              </td>
              <td class="bill-operations-table__column" align="right">
                {{ getOperationTypeName(operation.type) }}
              </td>
              <td class="bill-operations-table__column" align="right">
                {{ operation.value.value }}
              </td>
            </tr>
            <tr v-if="isLoading">
              <td colspan="5">
                <LoaderDots class="bill-operations-table__loading" />
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  </main>
</template>

<script>
import {mapGetters} from 'vuex';
import Alert from '~/components/elements/Alert';
import CustomSelect from '@/components/elements/CustomSelect';
import CustomInput from '@/components/elements/CustomInput';
import Button from '@/components/elements/Button';
import LoaderDots from '@/components/elements/LoaderDots';
import LazyLoader from '@/components/elements/LazyLoader';
import Spinner from '~/components/elements/LoaderSpinner';

import { billingOperationTypeName } from '@/plugins/enums/profile.js';
import pageMeta from '~/mixins/pageMeta';

export default {
  name: 'ProfileBills',
  components: {
    Spinner,
    Alert,
    CustomInput,
    CustomSelect,
    Button,
    LoaderDots,
    LazyLoader,
  },
  mixins: [pageMeta],
  middleware: ['createCard'],
  asyncData() {
    return {
      pageTitle: 'Мой счет',
    };
  },
  data() {
    return {
      countValue: null,
      isPending: false,
      cards: [],
      selectedCard: { title: 'Перевод по реквизитам', value: 'requisites' },
      limits: {},
      passport: {},
      payment_info: null,
      referral_bill: {},
      paramsOperation: null,
      operations: [],
      totalOperations: null,
      isLoading: false,
      paramsFetchItems: {},
      billAlert: {
        type: '',
        content: '',
      },
      isBillLoading: false,
    };
  },
  async fetch() {
    await this.updateBillData();

    this.paramsFetchItems = {
      pageNum: 1,
      perPage: 12,
    };

    await this.getBillOperations(this.paramsFetchItems);
  },
  computed: {
    ...mapGetters('currency', { getCurrency: 'getCurrency' }),
    cardOptions() {
      const options = this.cards.map((card) => ({ ...card, title: `${card.card_type} ${card.card_panmask}` }));
      // options.push({ title: 'Добавить новую карту', disabled: true, selected: false });
      options.push(this.newCardOption);
      options.push({ title: 'Перевод по реквизитам', disabled: true, selected: true });

      return options;
    },

    canGetNextPage() {
      return this.operations.length < this.totalOperations;
    },

    isFullPassport() {
      return (
        this.passport.surname &&
        this.passport.name &&
        this.passport.patronymic &&
        this.passport.serial &&
        this.passport.no &&
        this.passport.issue_date &&
        this.req.address
      );
    },

    newCardOption() { // Вывод на карту
      const backUrl = this.generateYandexCardAuthBackUrl();
      return {
        id: 'add',
        title: 'Добавить новую карту',
        url: this.generateYandexCardAuthUrl(backUrl, backUrl),
      };
    },
  },
  watch: {
    selectedCard(value) {
      if (value && value.url) document.location.href = value.url;
    },
  },
  methods: {
    async getBillOperations() {
      const { operations, range } = await this.$api.profile.getProfileBillOperations();

      this.operations = operations;
      this.totalOperations = range;
    },
    async getNextPageItems() {
      try {
        const nextPageNumber = this.paramsFetchItems.pageNum + 1;
        this.$set(this.paramsFetchItems, 'pageNum', nextPageNumber);
        this.isLoading = true;
        const { operations } = await this.$api.profile.getProfileBillOperations(this.paramsFetchItems);
        this.isLoading = false;
        this.operations.push(...operations);
      } catch (error) {
        console.error(error);
      }
    },
    async updateBillData() {
      // eslint-disable-next-line camelcase
      const { cards, limits, passport, payment_info, referral_bill } = await this.$api.profile.getProfileBill();
      this.cards = cards;
      this.limits = limits;
      this.passport = passport;
      // eslint-disable-next-line camelcase
      this.payment_info = payment_info;
      // eslint-disable-next-line camelcase
      this.referral_bill = referral_bill;
    },
    async acceptReturnBill() {
      if (this.selectedCard.value === 'requisites') {
        this.setBillAlert({
          type: 'error',
          content: 'Требуется уточнение паспортных данных. Свяжитесь с Вашим персональным менеджером или колл-центром.',
        })
      } else {
        try {
          this.isBillLoading = true
          const payload = { card_id: this.selectedCard.id, value: this.countValue };
          const response = await this.$api.profile.sendCashOutRequest(payload);

          if (response.status === 201) {
            this.setBillAlert({
              type: 'success',
              content: 'Денежные средства будут зачислены на указанные реквизиты в течении 10 дней',
            })

            this.paramsFetchItems = {
              pageNum: 1,
              perPage: 12,
            };

            await this.getBillOperations(this.paramsFetchItems);

          } else {
            this.setBillAlert({
              type: 'error',
              content: 'Произошла ошибка. Свяжитесь с Вашим персональным менеджером или колл-центром.',
            })
          }
        } catch (error) {
          this.setBillAlert({
            type: 'error',
            content: error.response.data.message,
          })
        } finally {
          this.isBillLoading = false
        }
      }
    },
    getOperationTypeName(type) {
      return billingOperationTypeName[type];
    },
    generateYandexCardAuthBackUrl() {
      if (!process.client) return ''
      return `${document.location.origin}/profile/bill`;
    },
    generateYandexCardAuthUrl(urlError, urlSuccess, identify = true) {
      return `https://yoomoney.ru/cardauth/payout?url_error=${urlError}&url_success=${urlSuccess}&identify=${identify}`;
    },
    setBillAlert({ type, content }) {
      this.billAlert = {
        type,
        content,
      }
    },
    removeBillAlert() {
      this.setBillAlert({ type: '', content: '' })
    },
  },
};
</script>

